$(() => {
  let isMenuShow = true;

  $('.burger-menu').click(() => {
    $('.nav-menu').slideToggle();
    if (isMenuShow) {
      isMenuShow = false;
      $('.burger-menu').css({ transform: 'rotate(90deg)' });
    } else {
      isMenuShow = true;
      $('.burger-menu').css({ transform: 'rotate(0deg)' });
    }
  });
});

const logo = document.querySelector('.header-menu__logo');
const btnSMS = document.querySelectorAll('.btn-sms');
const btnVPN = document.querySelectorAll('.btn-vpn');
const btnAntifrod = document.querySelectorAll('.btn-antifrod');
const btnSIP = document.querySelectorAll('.btn-sip');
const btnSys = document.querySelectorAll('.btn-sys');
const burgerMenu = document.querySelector('.burger-menu');

const scrollToSection = (e) => {
  window.scroll({
    left: 0,
    top: e.offsetTop,
    behavior: 'smooth',
  });
};

logo.addEventListener('click', () => {
  window.scroll({
    left: 0,
    top: 0,
    behavior: 'smooth',
  });
});

btnSMS.forEach((btn) => {
  btn.addEventListener('click', (e) => {
    e.preventDefault();
    const sms = document.querySelector('.sms');
    scrollToSection(sms);
  });
});

btnVPN.forEach((btn) => {
  btn.addEventListener('click', (e) => {
    e.preventDefault();
    const section = document.querySelector('.vpn');
    scrollToSection(section);
  });
});

btnAntifrod.forEach((btn) => {
  btn.addEventListener('click', (e) => {
    e.preventDefault();
    const section = document.querySelector('.antifrod');
    scrollToSection(section);
  });
});

btnSIP.forEach((btn) => {
  btn.addEventListener('click', (e) => {
    e.preventDefault();
    const section = document.querySelector('.sip');
    scrollToSection(section);
  });
});

btnSys.forEach((btn) => {
  btn.addEventListener('click', (e) => {
    e.preventDefault();
    const section = document.querySelector('.sys');
    scrollToSection(section);
  });
});

$('.service-btn-sms').click(() => {
  $('.contact-form-sms').fadeIn();
  $('.bg-shadows').fadeIn();
  $('.answer').empty();
});
$('.service-btn-vpn').click(() => {
  $('.contact-form-vpn').fadeIn();
  $('.bg-shadows').fadeIn();
  $('.answer').empty();
});
$('.service-btn-sys').click(() => {
  $('.contact-form-sys').fadeIn();
  $('.bg-shadows').fadeIn();
  $('.answer').empty();
});
$('.service-btn-sip').click(() => {
  $('.contact-form-sip').fadeIn();
  $('.bg-shadows').fadeIn();
  $('.answer').empty();
});
$('.service-btn-antifrod').click(() => {
  $('.contact-form-antifrod').fadeIn();
  $('.bg-shadows').fadeIn();
  $('.answer').empty();
});

$('.close-form').click(() => {
  $('.contact-form').fadeOut();
  $('.bg-shadows').fadeOut();
  $('.answer').empty();
});

$(document).mouseup((e) => {
  const form = $('.form');
  if (e.target != form[0] && form.has(e.target).length === 0) {
    $('.contact-form').fadeOut();
    $('.bg-shadows').fadeOut();
  }
});
