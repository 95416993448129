import $ from 'jquery';

jQuery(document).ready(($) => {
  const send = (name) => {
    const form = $(`#contact-form-${name}`);
    const btn = $(`.form-btn-${name}`);
    const answer = $('.answer');

    $.ajax({
      url: 'smtp/handlers/handler.php',
      type: 'POST',
      data: form.serialize(),
      beforeSend: () => {
        answer.empty();
        btn.attr('disabled', true).css('margin-bottom', '20px');
      },
      success: (result) => {
        answer.text(result);
        form.find('.field').val('');
        btn.attr('disabled', false);
      },
      error: () => {
        answer.text('Произошла ошибка! Попробуйте позже.');
        btn.attr('disabled', false);
      },
    });
  };

  const contactForm = '.contact-form-';

  $(contactForm + 'sms').on('submit', (e) => {
    e.preventDefault();
    send('sms');
  });

  $(contactForm + 'vpn').on('submit', (e) => {
    e.preventDefault();
    send('vpn');
  });

  $(contactForm + 'sip').on('submit', (e) => {
    e.preventDefault();
    send('sip');
  });

  $(contactForm + 'sys').on('submit', (e) => {
    e.preventDefault();
    send('sys');
  });

  $(contactForm + 'antifrod').on('submit', (e) => {
    e.preventDefault();
    send('antifrod');
  });
});

$('.phone').mask('+7 (999) 999-99-99');
